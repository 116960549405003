import { createRouter, createWebHashHistory } from 'vue-router';
const history = createWebHashHistory();
const router = createRouter({
    history,
    routes:[
        {
            path:'/',
            name:'监控',
            component: ()=>import('@/page/index')
        }
    ]
});
export default router